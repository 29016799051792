import React from 'react';
import { formatDate } from '../../helpers/dates';
import { doScrollToTop, getNewsDetailsLinkPath, isLiveNewsTag, resizedImageURL, srcsetImageURL } from '../../helpers/general';
import { Link } from 'react-router-dom';
import { shimmerData } from '../../helpers/constants';
import LiveAnimatedRedDot from './LiveAnimatedRedDot';
import { ArticleListTypes, doHardReloadArticle, getMobileDetect } from '../../constants';
import CGLink from './CGLink';
import NewsListView from './HomeNewsBoxes/NewsListView';
import NewsBoxView from './HomeNewsBoxes/NewsBoxView';
import NonNewsBoxView from './HomeNewsBoxes/NonNewsBoxView';
import NonNewsListView from './HomeNewsBoxes/NonNewsListView';

const mobileArticleListType = ArticleListTypes.list;
const md = getMobileDetect();
const isMobile = Boolean(md.mobile());
const searchParams = new URLSearchParams(window.location.search);
const zoomAnimation = searchParams.get("zoomAnimation") === "true";

const HomeNewsContainer = ({ title, data, subTitle = "", isLoading, isError, LinkTo, type = "" }) => {
    const newsData = isLoading ? shimmerData : data || [];
    const shimmerClassName = newsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return (
            <div className='p-4'></div>
        )
    }

    const firstNewsContainerData = newsData?.[0];
    const otherNewsContainerData = newsData?.slice(1);

    return (
        <div className='bg-white border rounded-2xl'>
            <div className='px-5 md:py-8 py-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] ${shimmerClassName} flex items-center min-w-[50px]`}>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h2 className='text-[16px] md:text-2xl'>{title}</h2>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <p className={`${shimmerClassName} text-center text-[#787878] md:text-base text-sm`}>{subTitle}</p>
            </div>

            <div className='px-[12px] md:px-5'>
                <div className={`${otherNewsContainerData.length > 0 ? "pb-4 border-[#E3E3E4] border-b md:border-none" : ""} ${zoomAnimation ? 'image-zoom-animation' : ''}`}>
                    <CGLink
                        doHardRedirect={doHardReloadArticle}
                        to={getNewsDetailsLinkPath(firstNewsContainerData?.slug, firstNewsContainerData?.id, firstNewsContainerData?.type, firstNewsContainerData)}
                        onClick={doScrollToTop}
                    >
                        <div className='news-image-box'>
                            <img src={resizedImageURL(firstNewsContainerData?.image, "332x166")} srcSet={srcsetImageURL(firstNewsContainerData?.image, "576x288", "576x288")} alt="news" className={` ${shimmerClassName} w-full md:h-[288px] h-[166px] object-cover rounded-xl`} />
                        </div>
                        <div>
                            <p className={`${shimmerClassName} min-h-6 mt-3 news-title md:leading-[22px] leading-[16px] font-semibold md:text-lg text-[13px]`}>
                                {firstNewsContainerData?.title}
                            </p>
                            <p className={`${shimmerClassName} flex items-center md:text-sm text-[10px] md:mt-3 mt-2 md:leading-[16px] leading-3 md:font-medium text-[#787878]`}>
                                {isLiveNewsTag(firstNewsContainerData?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                                {firstNewsContainerData?.tournamentName ? <span className='inline-block mx-1'>{firstNewsContainerData.tournamentName} •</span> : null}
                                {formatDate(firstNewsContainerData?.date)}
                            </p>
                        </div>
                    </CGLink>
                </div>
            </div>

            {
                otherNewsContainerData.length > 0 && type === 'news' &&
                <>
                    {mobileArticleListType === 'list' && isMobile ?
                        <NewsListView otherNewsContainerData={otherNewsContainerData} shimmerClassName={shimmerClassName} />
                        :
                        <NewsBoxView otherNewsContainerData={otherNewsContainerData} shimmerClassName={shimmerClassName} />
                    }
                </>
            }
            {
                otherNewsContainerData.length > 0 && type !== 'news' &&
                <>
                    {mobileArticleListType === 'list' && isMobile ?
                        <NonNewsListView otherNewsContainerData={otherNewsContainerData} shimmerClassName={shimmerClassName} />
                        :
                        <NonNewsBoxView otherNewsContainerData={otherNewsContainerData} shimmerClassName={shimmerClassName} />
                    }
                </>
            }
            <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
            </div>
            <div className='text-[#3A32D1] text-center py-3 md:text-sm text-xs font-semibold'>
                <Link
                    key='btnSeeMoreNews2'
                    to={LinkTo}
                    onClick={doScrollToTop}
                >
                    See More
                </Link>
            </div>
        </div>
    );
};

export default HomeNewsContainer;