import React from 'react'
import CGLink from '../CGLink'
import { doHardReloadArticle } from '../../../constants'
import { doScrollToTop, getNewsDetailsLinkPath, isLiveNewsTag, resizedImageURL, srcsetImageURL } from '../../../helpers/general'
import LiveAnimatedRedDot from '../LiveAnimatedRedDot'
import { formatDate } from '../../../helpers/dates'

export default function NonNewsBoxView({ otherNewsContainerData, shimmerClassName}) {
    const searchParams = new URLSearchParams(window.location.search);
    const zoomAnimation = searchParams.get("zoomAnimation") === "true";
    return (
        <>
            <div className={`flex overflow-x-auto hideScrollbar md:overflow-y-hidden md:items-stretch md:px-1`}>
                <div className={`md:gap-0 md:p-0 md:grid grid-cols-2 flex pt-6 gap-[10px] pb-2 px-3`}>
                    {otherNewsContainerData?.map((news, index) => (
                        <div
                            key={news?.id}
                            className={`md:w-full w-[140px] md:p-4 ${zoomAnimation ? 'image-zoom-animation' : ''}`}
                        >
                            <CGLink
                                doHardRedirect={doHardReloadArticle}
                                key={index}
                                to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type, news)}
                                onClick={doScrollToTop}
                            >
                                <div className='news-image-box'>
                                    <img src={resizedImageURL(news?.image, "160x85")} srcSet={srcsetImageURL(news?.image, "250x124", "250x124")} alt="news image" className={`${shimmerClassName} md:h-[124px] h-[84px] w-full rounded-xl object-cover`} />
                                </div>
                                <div>
                                    <p className={`${shimmerClassName} mt-2 news-title min-h-7 md:min-h-12 md:text-base text-[13px] font-semibold leading-[14px] md:leading-[22px]`}>
                                        {news?.title}
                                    </p>
                                    <p className={`${shimmerClassName} flex flex-wrap items-center mt-2 md:text-sm text-[10px] leading-[12px] md:leading-[16px] font-medium text-[#787878]`}>
                                        {isLiveNewsTag(news?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                                        {news?.tournamentName ? <span className='inline-block mx-1'>{news.tournamentName} •</span> : null}
                                        {formatDate(news?.date)}
                                    </p>
                                </div>
                            </CGLink>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
